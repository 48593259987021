import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useGetNotesQuery } from "./notesApiSlice"
import { memo } from 'react'

const Note = ({ noteId }) => {

  const { note } = useGetNotesQuery('notesList', {
    selectFromResult: ({ data }) => ({
      note: data?.entities[noteId]
    })
  })

  const navigate = useNavigate()

  if (note) {

    const created = new Date(note.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' })

    const updated = new Date(note.updatedAt).toLocaleString('ID', { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric'})

    const handleEdit = () => navigate(`/dash/notes/${noteId}`)

    return (

      <tr className="table__row">
        <td className="table__cell note__status">
          {
            note.completed
              ? <span className="note__status--completed">Completed</span>
              : <span className="note__status--open">Open</span>
          }
        </td>
        <td className="table__cell note__created">{created}</td>
        <td className="table__cell note__updated">{updated}</td>
        <td className="table__cell note__title">{note.title}</td>
        <td className="table__cell note__username">{note.username}</td> 
        {/* remember note.username is an additional key upon requesting all notes */}
        <td className="table__cell">
          <button
            className="icon-button table__button"
            onClick={handleEdit}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
        </td>
      </tr>

    )

  } else return null

}

const memoizeNote = memo(Note)

export default memoizeNote